import { useContext, useEffect } from "react";
import { useQuery, QueryCache } from "react-query";

import APIContext from "./APIContext";

const joinedTopics = {};

export function useTopic<T>(topic: string | null, callback: (data: T) => void) {
  const { io, socketConnection } = useContext(APIContext);

  useEffect(() => {
    if (topic === null) return;

    if (!(topic in joinedTopics)) {
      joinedTopics[topic] = 0;
    }
    joinedTopics[topic]++;

    socketConnection.then(() => {
      if (joinedTopics[topic] === 1) {
        io.emit("topic:subscribe", topic);
      }

      io.on(`topic-data:${topic}`, callback);
    });
    return () => {
      socketConnection.then(() => {
        io.off(`topic-data:${topic}`, callback);
        joinedTopics[topic] = Math.max(0, joinedTopics[topic] - 1);
        if (joinedTopics[topic] === 0) {
          io.emit("topic:unsubscribe", topic);
        }
      });
    };
  }, [io, socketConnection, topic, callback]);
}

export const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export function useImage(url: string, retNull = false) {
  const { api } = useContext(APIContext);

  return useQuery(
    [url, retNull],
    () =>
      !retNull
        ? new Promise<string>((resolve, reject) =>
            setTimeout(
              () =>
                api
                  .get(url, { responseType: "blob" })
                  .then((response) => URL.createObjectURL(response.data))
                  .then(resolve)
                  .catch(reject),
              1000
            )
          )
        : Promise.resolve(null),
    { staleTime: 1000 * 60 * 5 }
  );
}

export default function useAPI() {
  return useContext(APIContext).api;
}
