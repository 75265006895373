import { Text, Button } from "@hackthenorth/north";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { Item, Sku, useDeleteItem } from "../../../../util/api/api";

import EditModal from "./EditModal";

const Wrapper = styled.div`
  border-radius: 8px;
  padding: 1px 10px 5px 10px;
  margin-bottom: 10px;
  background: ${({ theme }) => theme.globalConstants.color.rgPrimaryLight};
`;

const Controls = styled.span`
  float: right;
`;

export default function SkuItem({
  item,
  sku,
  refetch,
}: {
  item: Item;
  sku: Sku;
  refetch: () => void;
}) {
  const [edit, setEdit] = useState(false);

  const [deleteItem, { isLoading }] = useDeleteItem(item.id);
  const onDeleteItem = useCallback(async () => {
    if (
      window.confirm(
        `Delete ${sku.name} #${item.id} permanently? Request history associated with this item will also be deleted.`
      )
    ) {
      await deleteItem();
      refetch();
    }
  }, [deleteItem, item.id, refetch, sku.name]);

  const onModalClose = useCallback(
    (shouldRefetch: boolean) => {
      setEdit(false);
      if (shouldRefetch) refetch();
    },
    [refetch]
  );

  return (
    <Wrapper>
      <Text as="h4" mods="heading h4">
        {sku.name} #{item.id}
        <Controls>
          <Button
            disabled={isLoading}
            mods="rgButton rgSpaceLeft rgSmall"
            onClick={() => setEdit(true)}
          >
            Edit Notes
          </Button>
          <Button
            disabled={isLoading}
            mods="rgButton rgSpaceLeft rgSmall"
            onClick={onDeleteItem}
          >
            Delete
          </Button>
        </Controls>
      </Text>
      <Text as="pre">{item.notes}</Text>
      {edit && <EditModal onClose={onModalClose} sku={sku} item={item} />}
    </Wrapper>
  );
}
