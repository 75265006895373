import React, { useMemo } from "react";

export default function NiceDate(props: { date: string }) {
  const pretty = useMemo(() => {
    const date = new Date(props.date);
    return new Intl.DateTimeFormat("default", {
      year:
        date.getFullYear() === new Date().getFullYear() ? undefined : "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
  }, [props.date]);
  return <>{pretty}</>;
}
