import { Text, Button } from "@hackthenorth/north";
import React, { useCallback, useState } from "react";

import SkuRow from "../../common/SkuRow";
import { ButtonContainer } from "../../common/User/Requests/Actions";
import {
  Sku,
  useFindRequest,
  useInventory,
  RequestStatus,
} from "../../util/api/api";
import useVisible from "../../util/useVisible";

import ItemModal from "./ItemModal";
import ItemRequestModal from "./ItemRequestModal";
import LotteryRequestModal from "./LotteryRequestModal";

export default function ItemRow({ sku }: { sku: Sku | null }) {
  const [visible, visibilityRef] = useVisible();
  const [available] = useInventory((visible && sku?.code) || null);
  const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [lotteryModalOpen, setLotteryModalOpen] = useState(false);
  const {
    data: pendingRequests,
    refetch: refetchPendingRequests,
  } = useFindRequest(
    RequestStatus.PENDING,
    sku?.code as string,
    sku?.type === "LOTTERY"
  );
  const closeRequestModal = useCallback(() => {
    if (sku?.type === "LOTTERY") {
      refetchPendingRequests().then(() => setRequestModalOpen(false));
    } else {
      setRequestModalOpen(false);
    }
  }, [sku, refetchPendingRequests]);
  const loading = sku === null;
  return (
    <SkuRow sku={sku} ref={visibilityRef}>
      <ButtonContainer>
        <Button
          mods="rgButton"
          disabled={loading}
          onClick={() => setDescriptionModalOpen(true)}
        >
          More information
        </Button>
        {sku?.type === "LOTTERY" && (
          <Button
            mods="primary rgButton rgSpaceLeft"
            style={{ opacity: 1 }}
            disabled={loading || !pendingRequests?.request}
            onClick={() => setLotteryModalOpen(true)}
          >
            {pendingRequests?.request
              ? "Perform Lottery Roll"
              : "No Requests Made"}
          </Button>
        )}
      </ButtonContainer>
      <Text style={{ marginLeft: 8, marginTop: 4, display: "inline-block" }}>
        {available !== null && available > 0 && `${available} available`}
      </Text>
      {descriptionModalOpen && sku && (
        <ItemModal sku={sku} onClose={() => setDescriptionModalOpen(false)} />
      )}
      {requestModalOpen && sku && (
        <ItemRequestModal sku={sku} onClose={closeRequestModal} />
      )}
      {lotteryModalOpen && sku && (
        <LotteryRequestModal
          sku={sku}
          onClose={async () => {
            await refetchPendingRequests();
            setLotteryModalOpen(false);
          }}
        />
      )}
    </SkuRow>
  );
}
