import { Button, DropdownInput, Flex, Spacer } from "@hackthenorth/north";
import React, { useState, useMemo, useCallback } from "react";
import styled from "styled-components";

import { Sku as TSku, Skutype, useSkus } from "../../../util/api/Sku";

import Edit from "./Edit";
import Sku from "./Sku";

export const InputWrapper = styled.div`
  flex: 1;
  margin-right: 1em;
`;

export interface Option {
  value: string | null;
  label: string;
}

export const empty = {
  name: "Name",
  code: "Code not found",
  shortDescription: "Description",
  longDescription: "Description",
  image: "Image",
  type: "CHECKOUT" as Skutype,
  location: "Location",
  categoryId: -1,
  items: [],
} as TSku;

const defaultOpt = {
  value: null,
  label: "Search skus...",
};

export default function Inventory() {
  const [query, setQuery] = useState<Option>(defaultOpt);
  const [skuCode, setSkuCode] = useState<string | null>(null);
  const { data: skus, refetch } = useSkus();

  const [editSku, setEditSku] = useState<TSku | undefined | false>(false);

  const options = useMemo(
    () =>
      skus?.map((sku) => {
        return { value: sku.code, label: sku.name };
      }) ?? [],
    [skus]
  );

  const handleSearch = (option: Option) => {
    setQuery(option);
    if (option.value != null) {
      setSkuCode(option.value);
    }
  };

  const onEditClose = useCallback(
    (skuCode?: string | null) => {
      setEditSku(false);
      if (typeof skuCode !== "undefined") {
        setSkuCode(skuCode);
        refetch();
      }
    },
    [refetch]
  );

  return (
    <>
      <Flex>
        <InputWrapper>
          <DropdownInput
            value={query}
            filterable={true}
            onChange={(option: Option) => {
              handleSearch(option);
            }}
            options={options}
          />
        </InputWrapper>
        <Button mods="rgButton" onClick={() => setEditSku(undefined)}>
          Add New Sku
        </Button>
      </Flex>
      <Spacer height={10} />
      {skuCode !== null && (
        <Sku skuCode={skuCode} setEditSku={setEditSku} onClose={onEditClose} />
      )}
      {editSku !== false && <Edit seed={editSku} onClose={onEditClose} />}
    </>
  );
}
