import { Text } from "@hackthenorth/north";
import React from "react";

import Footer from "../../static/img/footer.png";

import Categories from "./Categories";

export default function Home() {
  return (
    <>
      <Text as="h1" mods="heading h1">
        Home
      </Text>
      <Categories />
      <img src={Footer} alt="" style={{ width: "100%" }} />
    </>
  );
}
