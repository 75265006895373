import { Theme, MediaQueryTemplates } from "@hackthenorth/north";
import { css } from "styled-components";

import { buttonStyles } from "./componentStyles/button";
import { dropdownInputStyles } from "./componentStyles/dropdownInput";
import { flexStyles } from "./componentStyles/flex";
import { linkStyles } from "./componentStyles/link";
import { textStyles } from "./componentStyles/text";
import { textAreaInputStyles } from "./componentStyles/textAreaInput";
import { textInputStyles } from "./componentStyles/textInput";
import { deviceBreakpoints } from "./deviceBreakpoints";
import globalConstants from "./globalConstants";
import globalStyles from "./globalStyles";

/**
 * This merges our theme's interface with the default theme interface
 * and other declarations in styled-components, which provides
 * correct typing for the theme object in styled-components' ThemeProvider
 * (see https://www.styled-components.com/docs/api#typescript)
 */
declare module "styled-components" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {
    globalConstants: {
      color: {
        redPrimary1: string;
        redPrimary2: string;
        redPrimary3: string;

        bluePrimary1: string;
        bluePrimary2: string;
        bluePrimary3: string;

        redSecondary: string;
        greenSecondary: string;
        navySecondary: string;
        mustardSecondary: string;
        tealSecondary: string;
        tanSecondary: string;
        greySecondary: string;
        creamSecondary: string;

        gradientRedBlue135: string;
        gradientRedBlue172: string;

        white: string;
        textLight: string;
        textDark: string;
        textGrey: string;
        textLightGrey: string;

        rgPrimary: string;
        rgPrimaryLight: string;
      };
      fontFamily: {
        heading: string;
        body: string;
        code: string;
      };
      borderRadius: {
        small: number;
        medium: number;
        large: number;
      };
      fontSize: {
        title: number;
        h1: number;
        hmobile: number;
        h2: number;
        h3: number;
        bodyBig: number;
        body: number;
        bodySubtle: number;
      };
      padding: {
        medium: number;
        link: number;
      };
      boxShadow: {
        regular: string;
      };
    };
    mediaQueries: MediaQueryTemplates;
  }
}

const componentStyles = {
  text: textStyles,
  link: linkStyles,
  textInput: textInputStyles,
  button: buttonStyles,
  textAreaInput: textAreaInputStyles,
  dropdownInput: dropdownInputStyles,
  flex: flexStyles,
  modal: {
    modifiers: {
      primary: css`
        outline: 0;
        background-color: ${({ theme }) => theme.globalConstants.color.white};
      `,
    },
  },
};

const themeDef = {
  ...componentStyles,
  globalConstants,
  globalStyles,
  deviceBreakpoints,
};

export default themeDef;
