import { Button, Spacer, Text } from "@hackthenorth/north";
import React, { useCallback, useMemo, useState } from "react";

import Modal from "../../common/Modal";
import { usePerformLotteryRoll, Sku } from "../../util/api/api";

export default function LotteryRequestModal(props: {
  onClose: () => void;
  sku: Sku;
}) {
  const [isLoading, setLoading] = useState(false);
  const { onClose, sku } = props;

  const performLotteryRoll = usePerformLotteryRoll();

  const submit = useCallback(async () => {
    try {
      setLoading(true);
      await performLotteryRoll(sku.code).then(() => onClose());
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, [performLotteryRoll, onClose, sku.code]);

  const actions = useMemo(
    () => (
      <>
        <Button
          onClick={onClose}
          disabled={isLoading}
          mods="rgButton rgSpaceRight rgJustifyStart"
        >
          No
        </Button>
        <Button
          onClick={submit}
          disabled={isLoading}
          mods="rgButton rgSpaceLeft"
        >
          Yes
        </Button>
      </>
    ),
    [isLoading, onClose, submit]
  );

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title={`Are you sure you want to perform a lottery roll?`}
      actions={actions}
    >
      <Text as="p" mods="block">
        Performing a lottery roll will take all current pending requests for the
        item: {sku?.name} and randomly approve the necessary quantity.
      </Text>
      <Spacer height={20} />
    </Modal>
  );
}
