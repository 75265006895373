import { useCallback } from "react";
import { useQuery } from "react-query";

import { removeEmptyProperties } from "../removeEmptyProperties";

import useAPI from "./core";

export interface Category {
  id: number;
  name: string;
  image: string;
  description: string;
}

export function useCategories() {
  const api = useAPI();
  return useQuery("categories", () =>
    api.get<Category[]>("/categories").then((response) => response.data)
  );
}

export function useCreateCategory() {
  const api = useAPI();
  return useCallback(
    (cat: Category) =>
      api
        .post(`/categories/`, removeEmptyProperties(cat))
        .then((response) => response.data),
    [api]
  );
}

export function useUpdateCategory() {
  const api = useAPI();
  return useCallback(
    (cat: Category) =>
      api
        .put(`/categories/${cat.id}`, removeEmptyProperties(cat))
        .then((response) => response.data),
    [api]
  );
}

export function useDeleteCategory() {
  const api = useAPI();
  return useCallback(
    (cat: Category) =>
      api.delete(`/categories/${cat.id}`).then((response) => response.data),
    [api]
  );
}
