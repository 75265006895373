import { Text } from "@hackthenorth/north";
import React, { useCallback } from "react";
import styled from "styled-components";

import Image from "../../common/Image";
import { useNav } from "../../common/Navbar";
import { Category, useCategories, useCategoryImage } from "../../util/api/api";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5em;
`;

const ItemWrapper = styled.a`
  display: block;
  flex: 0;
  margin: 1em 1em 0 0;
  text-decoration: none;
`;

function Item(props: { category: Category }) {
  const { data: image } = useCategoryImage(props.category.id.toString());
  const [, , setNav, getNavUri] = useNav();

  const navigate = useCallback(() => {
    setNav("items", { q: props.category.name });
  }, [props.category.name, setNav]);

  return (
    <ItemWrapper
      onClick={navigate}
      href={getNavUri("items", { q: props.category.name })}
    >
      <Image
        src={image ?? undefined}
        style={{
          opacity: Number(typeof image === "string"),
          width: "8em",
          height: "8em",
        }}
      />
      <Text mods="bold">{props.category.name}</Text>
    </ItemWrapper>
  );
}

export default function Categories() {
  const { data } = useCategories();
  return (
    <>
      <Text as="h2" mods="heading h2">
        All categories
      </Text>
      <Text>Check out all of our items below!</Text>
      <Container>
        {data?.map((item) => (
          <Item key={item.id} category={item} />
        ))}
      </Container>
    </>
  );
}
