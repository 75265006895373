import { Button } from "@hackthenorth/north";
import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";

import {
  ItemRequestWithMetadata,
  RequestStatus,
  useMoveRequest,
  useInventory,
} from "../../../util/api/api";
import RequestModal from "../Requests/RequestModal";

export const ButtonContainer = styled.div`
  margin-top: 10px;
`;

const VERBS = {
  [RequestStatus.READY]: "Mark Ready",
  [RequestStatus.PENDING]: "Mark Pending",
  [RequestStatus.FULFILLED]: "Mark Fulfilled",
  [RequestStatus.RETURNED]: "Mark Returned",
  [RequestStatus.DENIED]: "Deny",
  [RequestStatus.CANCELED]: "Cancel Request",
  [RequestStatus.APPROVED]: "Approve",
};
function StatusButton({
  request,
  status,
  refetch,
  ...props
}: {
  request: ItemRequestWithMetadata;
  status: RequestStatus;
  refetch: () => void;
} & typeof Button["props"]) {
  const [sendMoveRequest, { isLoading, error }] = useMoveRequest();
  const [modelOpen, setModalOpen] = useState<boolean>(false);
  const [available] = useInventory(
    status === RequestStatus.APPROVED ? request.skuCode : null
  );

  const onClick = useCallback(async () => {
    if (status === RequestStatus.CANCELED || status === RequestStatus.DENIED) {
      setModalOpen(true);
    } else {
      await sendMoveRequest({ requestId: request.id, status });
      await refetch();
    }
  }, [sendMoveRequest, request.id, status, refetch]);

  useEffect(() => {
    if (error) alert("Error: " + error);
  }, [error]);

  return (
    <>
      <Button
        {...props}
        mods="rgButton rgSpaceRight"
        disabled={isLoading || available === 0}
        onClick={onClick}
      >
        {available === 0 ? "Out of stock" : VERBS[status]}
      </Button>
      {modelOpen && (
        <RequestModal
          submitReason={(abortReason: string) =>
            sendMoveRequest({ requestId: request.id, status, abortReason })
          }
          refetch={refetch}
          status={status}
          onSend={() => setModalOpen(false)}
        />
      )}
    </>
  );
}

export default function getActions(
  request: ItemRequestWithMetadata,
  refetch: () => void
) {
  if (request.status === RequestStatus.PENDING) {
    return (
      <ButtonContainer>
        <StatusButton
          mods="primary"
          refetch={refetch}
          request={request}
          status={RequestStatus.APPROVED}
        />
        <StatusButton
          refetch={refetch}
          request={request}
          status={RequestStatus.DENIED}
        />
      </ButtonContainer>
    );
  } else if (request.status === RequestStatus.APPROVED) {
    return (
      <ButtonContainer>
        <StatusButton
          mods="primary"
          refetch={refetch}
          request={request}
          status={RequestStatus.READY}
        />
        <StatusButton
          refetch={refetch}
          request={request}
          status={RequestStatus.CANCELED}
        />
      </ButtonContainer>
    );
  } else if (request.status === RequestStatus.READY) {
    return (
      <ButtonContainer>
        <StatusButton
          mods="primary"
          refetch={refetch}
          request={request}
          status={RequestStatus.FULFILLED}
        />
        <StatusButton
          refetch={refetch}
          request={request}
          status={RequestStatus.CANCELED}
        />
      </ButtonContainer>
    );
  } else if (request.status === RequestStatus.FULFILLED) {
    return (
      <ButtonContainer>
        <StatusButton
          mods="primary"
          refetch={refetch}
          request={request}
          status={RequestStatus.RETURNED}
        />
      </ButtonContainer>
    );
  }

  return null;
}
