import { Text } from "@hackthenorth/north";
import React, { useMemo } from "react";

import { ItemRequest } from "../../../util/api/api";
import Timeline from "../../Timeline";

export default function RequestTimeline(props: { request: ItemRequest }) {
  const items = useMemo(
    () =>
      props.request.history.map((item) => ({
        timestamp: item.timestamp,
        content: <Text>{item.note}</Text>,
        icon: null,
      })),
    [props.request]
  );
  return <Timeline items={items} />;
}
