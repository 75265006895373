import {
  Text,
  Flex,
  Spacer,
  Modal as NorthModal,
  UnstyledButton,
} from "@hackthenorth/north";
import React, { ReactElement } from "react";
import styled from "styled-components";

interface ModalProps extends React.ComponentPropsWithoutRef<"div"> {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  actions?: ReactElement;
}

const XIconButton = styled(UnstyledButton)`
  position: absolute;
  top: 24px;
  right: 32px;
`;

const ModalContainer = styled(NorthModal)`
  background: white;
  padding: 30px;
  border-radius: 20px;
  position: relative;
  flex: 0 0 100%;
  max-height: calc(100vh - 20px);
  box-sizing: border-box;
  overflow-y: auto;
  max-width: 600px;
  &:focus {
    outline: none;
  }
`;

const NO_OP = () => null;

const Modal: React.FC<ModalProps> = ({
  className,
  isOpen,
  onClose,
  title,
  actions,
  children,
}) => {
  return (
    <ModalContainer
      className={className}
      isOpen={isOpen}
      mods="primary"
      onClose={onClose || NO_OP}
      shouldCloseOnEsc={!!onClose}
      shouldCloseOnOverlayClick={!!onClose}
    >
      {onClose && (
        <XIconButton onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </XIconButton>
      )}
      {title && (
        <>
          <Text mods="h3 heading">{title}</Text>
        </>
      )}
      {children}
      {actions && (
        <>
          <Spacer height={32} />
          <Flex justify="flex-end" mods="wrap">
            {actions}
          </Flex>
        </>
      )}
    </ModalContainer>
  );
};

export default Modal;
