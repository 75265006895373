import Fuse from "fuse.js";
import { useMemo } from "react";

export function useSearch<T>(query: string, items: T[], fields: string[]) {
  const fuse = useMemo(
    () =>
      new Fuse(items.length > 1 && items[0] === null ? [] : items, {
        keys: fields,
      }),
    [items, fields]
  );
  const results = useMemo(
    () => fuse.search(query).map((result) => result.item),
    [fuse, query]
  );
  return query.length > 0 ? results : items;
}
