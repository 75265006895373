import { Text, Flex, Spinner } from "@hackthenorth/north";
import React from "react";
import { ReactQueryCacheProvider } from "react-query";
import { RecoilRoot } from "recoil";
import styled from "styled-components";

import { Navbar } from "./common/common";
import Page from "./pages/Pages";
import { useMe, queryCache } from "./util/api/api";
import { APIContextProvider } from "./util/api/APIContext";

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-size: ${({ theme }) => theme.globalConstants.fontSize.body};
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
`;

function App() {
  const { data: me, isLoading } = useMe();
  return isLoading ? (
    <Spinner />
  ) : me?.user?.admin ? (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <RecoilRoot>
        <Wrapper>
          <Navbar />
          <Page />
        </Wrapper>
      </RecoilRoot>
    </ReactQueryCacheProvider>
  ) : (
    <Flex justify="center" align="center">
      <Text as="h3" mods="heading h3 rgRequestInfoHeader">
        You are not authorized to access this tool.
      </Text>
    </Flex>
  );
}
export default function ({
  baseURL,
  authToken,
  reAuthenticate,
}: {
  baseURL: string;
  authToken: string;
  reAuthenticate: () => void;
}) {
  return (
    <APIContextProvider
      baseURL={baseURL}
      authToken={authToken}
      reAuthenticate={reAuthenticate}
    >
      <App />
    </APIContextProvider>
  );
}
