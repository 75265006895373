import { useCallback } from "react";
import { useMutation, useQuery } from "react-query";

import useAPI from "./core";
import { Item } from "./Item";
import { Sku } from "./Sku";
import { User } from "./User";

export enum RequestStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  READY = "READY",
  DENIED = "DENIED",
  FULFILLED = "FULFILLED",
  RETURNED = "RETURNED",
  CANCELED = "CANCELED",
}

export interface ItemRequest {
  id: number;
  status: RequestStatus;
  history: { timestamp: string; note: string }[];
  itemId: number | null;
  skuCode: string;
  sku: Sku;
  userId: string;
  createdAt: string;
  updatedAt: string;
}

export interface ItemRequestWithMetadata extends ItemRequest {
  item: Item | null;
}
export interface ItemRequestWithUser extends ItemRequest {
  user: User;
}

export function useRequestSku() {
  const api = useAPI();
  return useCallback((sku: string) => api.post(`/skus/${sku}/request`), [api]);
}

export function useFindRequest(
  status: RequestStatus,
  skuCode: string,
  enabled: boolean
) {
  const api = useAPI();
  return useQuery(
    ["find request", status],
    () => {
      return api
        .get<{ request: ItemRequestWithUser | null }>(`/requests/find`, {
          params: {
            status,
            skuCode,
          },
        })
        .then((response) => response.data);
    },
    {
      enabled,
    }
  );
}

export function useUserRequests(userId: string | null) {
  const api = useAPI();
  return useQuery(["user requests", userId], () =>
    userId === null
      ? Promise.resolve(null)
      : api
          .get<{ requests: ItemRequestWithMetadata[] }>(
            `/users/${userId}/requests`
          )
          .then((response) => response.data)
  );
}

export function usePerformLotteryRoll() {
  const api = useAPI();
  return useCallback(
    (skuCode: string) =>
      api.post("/requests/lottery", {
        skuCode,
      }),
    [api]
  );
}

export function useMoveRequest() {
  const api = useAPI();
  return useMutation<
    ItemRequest,
    unknown,
    { requestId: number; status: RequestStatus; abortReason?: string }
  >((props) =>
    api
      .put(`/requests/${props.requestId}`, {
        status: props.status,
        abortReason: props.abortReason,
      })
      .then((response) => response.data)
  );
}
