import {
  Button,
  Text,
  TextInput,
  Card,
  Spacer,
  DropdownInput,
} from "@hackthenorth/north";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import {
  Category,
  useCategories,
  useCreateCategory,
  useUpdateCategory,
  useDeleteCategory,
} from "../../../util/api/api";
import { base64Convert } from "../../../util/fileConversion";
import { translateErrors } from "../../../util/translateErrors";

const ErrorMessage = styled.p`
  color: red;
  font-weight: 500;
`;

const newCat = {
  id: -1,
  name: "",
  description: "",
  image: "",
} as Category;

export interface CategoryOption {
  value: number;
  label: string;
}

const defaultOpt = {
  value: -1,
  label: "Select a category",
};

export default function CategoryMenu() {
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [options, setOptions] = useState<CategoryOption[]>([]);
  const [catOption, setCatOption] = useState<CategoryOption>(defaultOpt);
  const [selected, setSelected] = useState<Category>(newCat);
  const [errors, setErrors] = useState<string[]>([]);
  // Fields
  const [id, setId] = useState(0);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [image, setImage] = useState<string>("");

  // Getting Categories from backend
  const { data: categories, refetch } = useCategories();

  const [maxId, setMaxId] = useState(1);

  const deleteCategory = useDeleteCategory();
  const updateCategory = useUpdateCategory();
  const createCategory = useCreateCategory();

  useEffect(() => {
    if (categories) {
      setOptions(
        categories.map((cat) => {
          return { value: cat.id, label: cat.name };
        })
      );
      const max = categories.reduce<Category | null>((max, curr) => {
        if (max === null) {
          return curr;
        }
        if (max.id > curr.id) {
          return max;
        } else {
          return curr;
        }
      }, null);
      if (max !== null) {
        setMaxId(max.id);
      }
    }
  }, [categories]);

  const handleSelect = (option: CategoryOption) => {
    setCatOption(option);
    if (categories) {
      setSelected(categories.filter((cat) => cat.id === option.value)[0]);
    }
    setShow(true);
  };

  const handleEdit = () => {
    setId(selected.id);
    setName(selected.name);
    setDescription(selected.description);
    setEdit(true);
  };

  const handleCancel = () => {
    refetch();
    setShow(false);
    setEdit(false);
    setId(0);
    setImage("");
    setName("");
    setDescription("");
    setSelected(newCat);
    setCatOption(defaultOpt);
  };

  const fileUpload = async (e: React.FormEvent<HTMLInputElement>) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (typeof file !== "undefined") {
      const base64 = (await base64Convert(file)) as string;
      const url = base64.split(",")[1];
      setImage(url);
    }
  };

  const handleDelete = () => {
    deleteCategory(selected);
    handleCancel();
  };

  const handleSave = () => {
    const cat: Category = {
      name,
      description,
      id: maxId,
      image,
    };

    let catRes = undefined;
    if (id !== 0) {
      // Update, PUT
      cat.id = id;
      catRes = updateCategory(cat);
    } else {
      // Create, POST
      catRes = createCategory(cat);
    }
    catRes.then((response) => {
      if (response.error) {
        setErrors(translateErrors(response.error));
      } else {
        handleCancel();
      }
    });
  };

  return (
    <Card
      style={{
        backgroundColor: "#FFFFFF",
        border: "1px solid #008AC4",
        boxSizing: "border-box",
        borderRadius: "5px",
        padding: "20px",
      }}
    >
      <Text as="h2" mods="heading h2">
        Categories
      </Text>
      <Button
        mods="rgButton"
        onClick={() => {
          handleCancel();
          setEdit(true);
          setShow(true);
        }}
      >
        Create
      </Button>
      {!edit && (
        <>
          <Spacer height={10} />
          <DropdownInput
            style={{ width: "40%", height: "3em" }}
            placeholder="Select a category"
            value={catOption}
            filterable={true}
            onChange={(e: CategoryOption) => {
              handleCancel();
              handleSelect(e);
            }}
            disabled={!options.length}
            options={options}
          />
        </>
      )}
      <Spacer height={10} />
      {!show ? null : selected && !edit ? (
        <>
          <Text as="h2" mods="heading h2" style={{ marginTop: "5px" }}>
            Name
          </Text>
          <Spacer />
          <Text style={{ marginTop: "5px" }}>{selected.name}</Text>
          <Spacer />
          <Text as="h2" mods="heading h2" style={{ marginTop: "5px" }}>
            Description
          </Text>
          <Spacer />
          <Text style={{ marginTop: "5px" }}>{selected.description}</Text>
          <Spacer height={10} />
          <div>
            <Button
              mods="rgButton rgSpaceRight"
              onClick={() => {
                handleEdit();
              }}
            >
              Edit
            </Button>
            <Button
              mods="rgButton rgSpaceRight"
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </Button>
          </div>
        </>
      ) : (
        <>
          <Text as="h2" mods="heading h2" style={{ marginTop: "5px" }}>
            Photo
          </Text>
          <input type="file" onChange={(e) => fileUpload(e)} />
          <Text as="h2" mods="heading h2" style={{ marginTop: "5px" }}>
            Name
          </Text>
          <Spacer />
          <TextInput
            style={{ marginTop: "5px" }}
            placeholder={"Add a name"}
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setName(e.target.value);
            }}
          />
          <Spacer />
          <Text as="h2" mods="heading h2" style={{ marginTop: "5px" }}>
            Description
          </Text>
          <Spacer />
          <TextInput
            style={{ marginTop: "5px" }}
            placeholder={"Add a description"}
            value={description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDescription(e.target.value);
            }}
          />
          <Spacer height={15} />
          {errors.map((error) => (
            <ErrorMessage key={error}>{error}</ErrorMessage>
          ))}
          <Spacer height={20} />
          <div>
            <Button mods="rgButton rgSpaceRight" onClick={() => handleSave()}>
              Save
            </Button>
            <Button mods="rgButton rgSpaceRight" onClick={() => handleCancel()}>
              Cancel
            </Button>
          </div>
        </>
      )}
    </Card>
  );
}
