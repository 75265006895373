import { Text, Button, Spacer, TextAreaInput } from "@hackthenorth/north";
import React, { useCallback, useState } from "react";

import Modal from "../../../../common/Modal";
import { Item, Sku, useUpdateItem } from "../../../../util/api/api";

export default function EditModal(props: {
  onClose: (refetch: boolean) => void;
  sku: Sku;
  item: Item;
}) {
  const { onClose, sku, item } = props;

  const [notes, setNotes] = useState(item.notes);

  const [updateItem, { isLoading }] = useUpdateItem(item.id);

  const onSubmit = useCallback(async () => {
    await updateItem({ notes });
    onClose(true);
  }, [notes, onClose, updateItem]);

  const actions = (
    <>
      <Button
        mods="rgButton rgSpaceRight"
        disabled={isLoading}
        onClick={() => onClose(false)}
      >
        Cancel
      </Button>
      <Button
        mods="rgButton"
        disabled={isLoading || notes === item.notes}
        onClick={onSubmit}
      >
        Save
      </Button>
    </>
  );

  return (
    <Modal
      isOpen={true}
      onClose={() => onClose(false)}
      actions={actions}
      title={`${sku.name} #${item.id}`}
    >
      <Text as="h4" mods="heading h4">
        Notes
      </Text>
      <Spacer height={10} />
      <TextAreaInput
        placeholder={"Add Notes"}
        value={notes}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setNotes(e.target.value);
        }}
      />
    </Modal>
  );
}
