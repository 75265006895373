import { Button, Text, DropdownInput, Flex } from "@hackthenorth/north";
import React, { useState } from "react";

import { RequestStatus } from "../../../util/api/api";
import Modal from "../../Modal";

export interface Reason {
  label: string;
  value: string;
}

const OPTIONS: Reason[] = [
  {
    label: "The quantity you requested is not available",
    value: "The quantity you requested is not available",
  },
  {
    label: "The quantity you requested is unreasonably high",
    value: "The quantity you requested is unreasonably high",
  },
  {
    label: "The item you requested is no longer available",
    value: "The item you requested is no longer available",
  },
  {
    label:
      "The item you requested is a lottery item and was given to a different hacker",
    value:
      "The item you requested is a lottery item and was given to a different hacker",
  },
  {
    label:
      "The overall number of items you requested is unreasonably high. Please contact us so we can help you work out your hardware order",
    value:
      "The overall number of items you requested is unreasonably high. Please contact us so we can help you work out your hardware order",
  },
  {
    label: "You did not sign up for a Hardware pick-up time within 24 hours",
    value: "You did not sign up for a Hardware pick-up time within 24 hours",
  },
  {
    label:
      "You requested this item too close to the pickup time you already signed up for. This means we can pack your existing approved items, but can’t add the one you just requested",
    value:
      "You requested this item too close to the pickup time you already signed up for. This means we can pack your existing approved items, but can’t add the one you just requested",
  },
  {
    label:
      "You did not show up to pick up your hardware at the time you signed up for",
    value:
      "You did not show up to pick up your hardware at the time you signed up for",
  },
  {
    label:
      "You already picked up hardware and we can’t accommodate an additional order for you",
    value:
      "You already picked up hardware and we can’t accommodate an additional order for you",
  },
  {
    label: "There are no remaining hardware pick-up spots",
    value: "There are no remaining hardware pick-up spots",
  },
  {
    label: "Hardware pickup is cancelled",
    value: "Hardware pickup is cancelled",
  },
];

export default function RequestModal(props: {
  onSend: () => void;
  status: RequestStatus.CANCELED | RequestStatus.DENIED;
  refetch: () => void;
  submitReason: (abortReason: string) => Promise<unknown>;
}) {
  const { status, submitReason, onSend, refetch } = props;
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [reason, setReason] = useState<Reason>({
    label: "Select a reason",
    value: "",
  });

  const send = async () => {
    const errors: string[] = [];
    setLoading(true);
    try {
      if (!reason.value) {
        errors.push("Reason not selected");
      } else {
        await submitReason(reason.value);
        await refetch();
      }
    } catch (e) {
      if (typeof e === "string") {
        errors.push(e.toLowerCase());
      }
    }
    if (errors.length === 0) {
      onSend();
    } else {
      setLoading(false);
      setErrors(errors);
    }
  };

  const actions = (
    <>
      <Flex justify="space-between">
        <Text mods="error">{errors.join(", ")}</Text>
        <div>
          <Button
            disabled={loading}
            mods="rgButton rgSpaceRight"
            onClick={onSend}
          >
            Close
          </Button>
          <Button disabled={loading} mods="rgButton primary" onClick={send}>
            {status === RequestStatus.CANCELED
              ? "Cancel Request"
              : "Decline Request"}
          </Button>
        </div>
      </Flex>
    </>
  );

  return (
    <Modal
      isOpen={true}
      title={`${
        status === RequestStatus.CANCELED ? "Cancellation" : "Denial"
      } Reason`}
      actions={actions}
    >
      <div style={{ height: "210px" }}>
        <DropdownInput
          value={reason}
          filterable={false}
          onChange={(option: Reason) => setReason(option)}
          options={OPTIONS}
        />
      </div>
    </Modal>
  );
}
