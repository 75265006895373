import React, { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import styled from "styled-components";

import { serializeRoute, deserializeRoute } from "../config/router";

export type Page =
  | "home"
  | "items"
  | "requests"
  | "users"
  | "pending"
  | "inventory";

export type Params = Record<string, string>;
export interface State {
  route: Page;
  params: Params;
}

const navPage = atom<Page>({ key: "navState", default: "home" });
const navParams = atom<Record<string, string>>({
  key: "navParams",
  default: {},
});

export function useNav<T extends Params>() {
  const [page, setPage] = useRecoilState(navPage);
  const [params, setParams] = useRecoilState(navParams);
  const setNav = useCallback(
    (page: Page, params: Params = {}) => {
      setPage(page);
      setParams(params);
      location.hash = serializeRoute({ route: page, params });
    },
    [setPage, setParams]
  );
  const getNavUri = useCallback((page: Page, params: Params = {}) => {
    return serializeRoute({ route: page, params });
  }, []);
  return [page, params, setNav, getNavUri] as [
    Page,
    T,
    (page: Page, params?: Params) => void,
    (page: Page, params?: Params) => string
  ];
}

export const NavWrapper = styled.div`
  padding: ${({ theme }) => theme.globalConstants.padding.medium};
  border-bottom: 1px solid
    ${({ theme }) => theme.globalConstants.color.textDark};
`;

export const NavLink = styled.a`
  display: inline-block;
  padding: ${({ theme }) => theme.globalConstants.padding.medium};
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.large}px;
  color: ${({ theme }) => theme.globalConstants.color.textDark};
  text-decoration: none;
  &.active {
    background: ${({ theme }) => theme.globalConstants.color.bluePrimary3};
  }
`;

function Link(params: {
  current: Page;
  path: Page;
  text: string;
  setNav: ReturnType<typeof useNav>[2];
  getNavUri: ReturnType<typeof useNav>[3];
}) {
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      params.setNav(params.path);
    },
    [params]
  );

  return (
    <NavLink
      className={params.current === params.path ? "active" : ""}
      href={params.getNavUri("home")}
      onClick={onClick}
    >
      {params.text}
    </NavLink>
  );
}

export default function Navbar() {
  const [page, , setNav, getNavUri] = useNav();

  const control = { current: page, setNav, getNavUri };

  useEffect(() => {
    const { route, params } = deserializeRoute(window.location.href, {
      route: "home",
      params: {},
    });
    setNav(route, params);
  }, [setNav]);

  return (
    <NavWrapper>
      <Link text="Home" path="home" {...control} />
      <Link text="Items" path="items" {...control} />
      <Link text="Requests" path="requests" {...control} />
      <Link text="Users" path="users" {...control} />
      <Link text="Inventory" path="inventory" {...control} />
    </NavWrapper>
  );
}
