import { useQuery, useMutation } from "react-query";

import useAPI from "./core";

export interface Item {
  id: number;
  notes: string;
  skuCode: string;
}

export function useItem(skuCode: string | null, code: string | null) {
  const api = useAPI();
  return useQuery("item", () =>
    api
      .get<Item[]>(`/items/${skuCode}/${code}`)
      .then((response) => response.data)
  );
}

export function useCreateItem(skuCode: string) {
  const api = useAPI();
  return useMutation<Item>(() =>
    api.post(`/items`, { skuCode, notes: "" }).then((response) => response.data)
  );
}

export function useUpdateItem(itemId: number) {
  const api = useAPI();
  return useMutation<Item, unknown, { notes: string }>((data) =>
    api.put(`/items/${itemId}`, data).then((response) => response.data)
  );
}

export function useDeleteItem(itemId: number) {
  const api = useAPI();
  return useMutation<Item>(() =>
    api.delete(`/items/${itemId}`).then((response) => response.data)
  );
}
