export const translateErrors = (
  data:
    | {
        errors: { errors: { param: string; msg: string[] }[] };
      }
    | string[]
) => {
  if ("errors" in data) {
    return data.errors.errors.map((err) => {
      if (err.param === "id") {
        return "Id " + err.msg;
      } else if (err.param === "message") {
        return "Message +" + err.msg;
      } else if (err.param === "name") {
        return "Name " + err.msg;
      } else if (err.param === "shortDescription") {
        return "Short description " + err.msg;
      } else if (err.param === "longDescription") {
        return "Long description " + err.msg;
      } else if (err.param === "location") {
        return "Location " + err.msg;
      } else if (err.param === "categoryId") {
        return "Selected category " + err.msg;
      } else if (err.param === "type") {
        return "Sku type " + err.msg;
      } else if (err.param === "image") {
        return "Image " + err.msg;
      } else if (err.param === "code") {
        return "Sku code " + err.msg;
      } else if (err.param === "description") {
        return "Description " + err.msg;
      } else {
        return "Unknown error.";
      }
    });
  }
  return data;
};
