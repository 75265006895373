import { Text } from "@hackthenorth/north";
import cls from "classnames";
import React, { MutableRefObject, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { Sku, Skutype, useSkuImage, Item, useMe } from "../util/api/api";
import useVisible from "../util/useVisible";

import Image from "./Image";
import PlaceLoader from "./PlaceLoader";

const SkuContainer = styled.div`
  margin-bottom: 1em;
  margin-top: -1em;
  @keyframes fadeIn {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn ease 500ms;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    filter: grayscale(1);
  }
  display: flex;
  padding: 1em 0;
  border-bottom: 1px solid
    ${({ theme }) => theme.globalConstants.color.textDark};
  &.small {
    opacity: 0.75;
    border-color: transparent;
  }
  &[role="button"] {
    padding: 0.5em 0;
    cursor: pointer;
    border-style: none;
    &:hover {
      opacity: 1;
      .skuDetailsPrompt {
        display: block !important;
      }
      .skuRight {
        display: none !important;
      }
    }
  }
`;

const ItemLabel = styled.div<{ type: Skutype | undefined }>`
  padding: 6px 40px;
  background: ${(props) =>
    props.type === "CHECKOUT"
      ? `#4779A4`
      : props.type === "LOTTERY"
      ? `#cc1a35`
      : `#006400`};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  max-width: 120px;
  color: #ffffff;
  font-size: 11px;
  margin-top: 5px;
  margin-bottom: 10px;
  height: fit-content;
`;

const SkuCenter = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;
const SkuText = styled(Text)`
  display: block !important;
  line-height: 1.5em !important;
`;

const SkuDetailsPrompt = styled(Text)`
  border-color: ${({ theme }) => theme.globalConstants.color.lightGrey};
  display: none !important;
  flex: 0;
  white-space: nowrap;
`;

const SkuRight = styled.div`
  flex: 0;
`;

const SkuHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  &.centered {
    align-items: center;
  }
`;

const SkuHeaderText = styled(Text)`
  flex: 1;
  @media (max-width: 600px) {
    flex: 0 0 100%;
  }
`;

const ImageWrapper = styled.div`
  margin-right: 1em;
  @media (max-width: 600px) {
    display: none;
  }
`;

interface SkuProps {
  sku: Sku | null;
  item?: Item | null;
  right?: React.ReactElement;
  disabled?: boolean;
  small?: boolean;
  children?: React.ReactNode;
}

const SkuRow = React.forwardRef<HTMLElement | null | undefined, SkuProps>(
  ({ sku, right, children, item, disabled, small = false }, ref) => {
    const { data: user } = useMe();

    const [imageVisible, visibilityRef] = useVisible();

    const [open, setOpen] = useState(false);
    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
          e.preventDefault();
          setOpen(!open);
        }
      },
      [open]
    );

    const { data: image } = useSkuImage((imageVisible && sku?.code) || null);

    const imageStyle = useMemo(
      () =>
        small
          ? {
              width: "2em",
              height: "2em",
            }
          : {},
      [small]
    );

    const handleRef = useCallback(
      (e: HTMLDivElement) => {
        if (ref) {
          if (typeof ref === "function") {
            ref(e);
          } else {
            (ref as MutableRefObject<HTMLElement>).current = e;
          }
        }
        visibilityRef.current = e;
      },
      [ref, visibilityRef]
    );

    const content = (
      <>
        <SkuText>
          {sku ? (
            user?.user.admin ? (
              `Located in ${sku.location}`
            ) : (
              sku.shortDescription
            )
          ) : (
            <PlaceLoader width={150} />
          )}
        </SkuText>
        {children}
      </>
    );

    return (
      <SkuContainer
        role={small ? "button" : "layout"}
        tabIndex={small ? 0 : undefined}
        className={cls({ small, disabled })}
        ref={handleRef}
        aria-label={
          small
            ? open
              ? "Show more details"
              : "Show fewer details"
            : undefined
        }
        onClick={() => setOpen(!open)}
        onKeyDown={small ? handleKeyDown : undefined}
      >
        <ImageWrapper>
          <Image
            src={image ?? undefined}
            style={{
              opacity: Number(typeof image === "string"),
              ...imageStyle,
            }}
          />
        </ImageWrapper>
        <SkuCenter>
          <SkuHeader className={cls({ centered: small && !open })}>
            <SkuHeaderText mods="bold">
              {sku?.name ?? <PlaceLoader width={80} />}{" "}
              <Text mods="subtle" as="span">
                {sku
                  ? `(${item ? `Item #${item.id}` : `No item assigned yet`})`
                  : null}
              </Text>
            </SkuHeaderText>
          </SkuHeader>
          {(!small || open) && <div>{content}</div>}
        </SkuCenter>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <SkuRight className="skuRight">{right}</SkuRight>
          {small && (
            <div>
              <SkuDetailsPrompt className="skuDetailsPrompt">
                ({open ? "hide" : "show"} details)
              </SkuDetailsPrompt>
            </div>
          )}
          {(!small || (small && open)) && (
            <ItemLabel type={sku?.type}>{sku?.type}</ItemLabel>
          )}
        </div>
      </SkuContainer>
    );
  }
);

export default SkuRow;
