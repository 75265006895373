import { Text } from "@hackthenorth/north";
import React, { ReactElement, useMemo } from "react";
import styled from "styled-components";

import NiceDate from "./NiceDate";

const Wrapper = styled.div`
  position: relative;
  padding: 1em 0;
`;

const ItemWrapper = styled.div`
  display: block;
  position: relative;
  padding-bottom: 0.5em;
  padding-left: 0.75em;
  margin-left: 0.4em;
  border-left: 2px solid ${({ theme }) => theme.globalConstants.color.textDark};
`;

const ItemDot = styled.div`
  border-radius: 100%;
  width: 0.9em;
  height: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: ${({ theme }) => theme.globalConstants.color.textDark};
  left: -0.5em;
  top: 0.2em;
`;

interface TimelineItem {
  timestamp: string;
  content: ReactElement | string;
}

export default function Timeline(props: { items: TimelineItem[] }) {
  const items = useMemo(
    () =>
      [...props.items].sort(({ timestamp: a }, { timestamp: b }) =>
        a < b ? 1 : a > b ? -1 : 0
      ),
    [props.items]
  );
  return (
    <Wrapper>
      {items.map((item, index) => (
        <ItemWrapper key={`${item.timestamp}-${index}`}>
          <ItemDot />
          <Text mods="subtle block bold">
            <NiceDate date={item.timestamp} />
          </Text>
          {item.content}
        </ItemWrapper>
      ))}
    </Wrapper>
  );
}
