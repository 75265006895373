import axios from "axios";
import React, { createContext, useMemo } from "react";
import socketio from "socket.io-client";

const APIContext = createContext({
  io: socketio({ autoConnect: false }),
  api: axios.create(),
  socketConnection: Promise.resolve(),
});
export default APIContext;

export function APIContextProvider({
  baseURL,
  authToken,
  reAuthenticate,
  children,
}: {
  authToken: string;
  reAuthenticate: () => void;
  baseURL: string;
  children: React.ReactNode;
}) {
  const value = useMemo(() => {
    const baseURLParts = new URL(baseURL);
    const api = axios.create({
      baseURL,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.message.includes("401")) {
          reAuthenticate();
        }
        return Promise.reject(error?.response?.data?.error?.message);
      }
    );
    const io = socketio(baseURLParts.origin, {
      path: `${baseURLParts.pathname}/socket.io`,
    });
    return {
      api,
      io,
      socketConnection: new Promise<void>((resolve) => {
        io.on("connect", resolve);
      }),
    };
  }, [baseURL, authToken, reAuthenticate]);
  return <APIContext.Provider value={value}>{children}</APIContext.Provider>;
}
