import { Button, Text, Spacer } from "@hackthenorth/north";
import React, { useState } from "react";
import { useCSVReader, formatFileSize } from "react-papaparse";

import { useNav } from "src/common/Navbar";

import Modal from "../../../common/Modal";
import { Skutype, SkuUpload, useUploadSkus } from "../../../util/api/Sku";

import { styles, WarningMessage, ErrorMessage } from "./styles";

interface Error {
  value: string;
  msg: string;
  param: string;
}

interface CSVResults {
  data: string[];
}

interface Props {
  onClose: () => void;
}

export default function CSVReader({ onClose }: Props) {
  const [, , setNav] = useNav();

  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [data, setData] = useState<SkuUpload[]>([]);
  const [errors, setErrors] = useState<String[]>([]);

  const uploadSkus = useUploadSkus();

  const onUpload = (results: CSVResults) => {
    const data: string[] = results.data;

    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const type = row[5].toUpperCase();

      if (
        !row[0] ||
        !row[1] ||
        !row[2] ||
        !row[3] ||
        !row[4] ||
        !row[5] ||
        !row[6] ||
        !row[7]
      ) {
        setErrors([`Incomplete Row - (Row #${i + 1})`]);
        return;
      }

      if (type !== "CHECKOUT" && type !== "LOTTERY" && type !== "FREE") {
        setErrors([
          `Invalid type, must be CHECKOUT, LOTTERY, or FREE - (Row #${i + 1})`,
        ]);
        return;
      }

      if (row.length !== 8) {
        setErrors([`Invalid number of columns (Row #${i + 1})`]);
        return;
      }
    }

    const skus = data.map(
      (row) =>
        ({
          name: row[0],
          code: row[1],
          category: row[2],
          shortDescription: row[3],
          longDescription: row[4],
          type: row[5] as Skutype,
          location: row[6],
          quantity: parseInt(row[7]),
        } as SkuUpload)
    );

    setData(skus);
  };

  const handleSubmit = () => {
    uploadSkus(data).then((res) => {
      if (res.error) {
        const errors = res.error.errors.errors.map(
          (err: Error) => `${err.param.split(".")[1]} (${err.value}) ${err.msg}`
        );
        setErrors(errors);
      } else if (res.errors) {
        setErrors(res.errors);
      } else {
        setNav("items");
      }
    });
  };

  return (
    <Modal isOpen={true} onClose={onClose} title={"Upload CSV"}>
      <Text as="p" mods="subtle block">
        Instead of adding SKUs individually, you can add them in bulk by
        uploading a CSV.
      </Text>
      <Spacer height={15} />
      <WarningMessage>
        USE WITH CAUTION: WILL REPLACE EXISTING ITEMS
      </WarningMessage>
      <Spacer height={15} />
      <Text as="p" mods="subtle block">
        Please ensure your CSV follow the following format:{" "}
      </Text>
      <CSVReader
        onUploadAccepted={onUpload}
        onDragOver={(event: DragEvent) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event: DragEvent) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {/* eslint-disable @typescript-eslint/no-explicit-any*/}
        {({ getRootProps, acceptedFile, getRemoveFileProps, Remove }: any) => (
          <>
            <div
              {...getRootProps()}
              style={Object.assign(
                {},
                styles.zone,
                zoneHover && styles.zoneHover
              )}
            >
              {acceptedFile ? (
                <>
                  <div style={styles.file}>
                    <div style={styles.info}>
                      <span style={styles.size}>
                        {formatFileSize(acceptedFile.size)}
                      </span>
                      <span style={styles.name}>{acceptedFile.name}</span>
                    </div>
                    <div {...getRemoveFileProps()} style={styles.remove}>
                      <Remove />
                    </div>
                  </div>
                </>
              ) : (
                "Drag and drop file OR Select File"
              )}
            </div>
          </>
        )}
      </CSVReader>
      <Spacer height={15} />
      {errors.map((error, i) => (
        <ErrorMessage key={i}>{error}</ErrorMessage>
      ))}
      <Button
        mods={{
          rgButton: true,
          rgSpaceRight: true,
        }}
        onClick={onClose}
      >
        Cancel
      </Button>
      <Button
        mods={{
          rgButton: true,
          rgSpaceRight: true,
        }}
        disabled={data.length == 0}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </Modal>
  );
}
