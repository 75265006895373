import { State, Page } from "../common/Navbar";

function reduceParams(params: string[]) {
  return params.reduce((acc, param) => {
    const [p, v] = param.split("=");
    acc[p] = v;
    return acc;
  }, {});
}

export function serializeRoute(state: State) {
  let params = Object.keys(state.params)
    .map((param) => `${param}=${encodeURIComponent(state.params[param])}`)
    .join("&");
  if (params.length > 0) {
    params = "+" + params;
  }
  return `#${state.route}${params}`;
}

export function deserializeRoute(uri: string, defaultState: State): State {
  const parts = uri.split("#");
  if (parts.length === 2) {
    const [route, paramStr] = parts[1].split("+", 2) as [Page, string];
    const params = paramStr ? reduceParams(paramStr.split("&")) : {};
    return { route, params };
  }
  return defaultState;
}
