import { useCallback } from "react";
import { useMutation, useQuery } from "react-query";

import useAPI from "./core";

export interface User {
  uid: string;
  name: string;
  email: string;
  notes: string;
  phone: string;
  createdAt: string;
  updatedAt: string;
  registered: boolean;
}

export interface Notification {
  id: number;
  userId: string;
  message: string;
  createdAt: string;
  updatedAt: string;
}

export interface Identification {
  id: number;
  userId: string;
  retrievedByUserId: string;
  returnedByUserId: string | null;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export interface IdentificationWithUser extends Identification {
  user: User;
  retrievedBy: User;
  returnedBy: User | null;
}

export interface UserWithAdmin extends User {
  admin: boolean;
}

export function useUsersWithRequests() {
  const api = useAPI();
  return useQuery(["usersWithRequests"], () =>
    api
      .get<{ users: User[] }>(`/users`, {
        params: {
          hasRequests: true,
        },
      })
      .then((response) => response.data)
  );
}

export function useUsers() {
  const api = useAPI();
  return useQuery(["users"], () =>
    api.get<{ users: User[] }>(`/users`).then((response) => response.data)
  );
}

export function useMe() {
  const api = useAPI();
  return useQuery(["me"], () =>
    api
      .get<{ user: UserWithAdmin }>(`/users/me`)
      .then((response) => response.data)
  );
}

export function useUser(id: string) {
  const api = useAPI();
  return useQuery(["user", id], () =>
    api
      .get<{ user: UserWithAdmin }>(`/users/${id}`)
      .then((response) => response.data)
  );
}

export function useNotifications(userId: string) {
  const api = useAPI();
  return useQuery(["notifications", userId], () =>
    api
      .get<{ notifications: Notification[] }>(`/users/${userId}/notifications`)
      .then((response) => response.data)
  );
}

export function useSendNotification() {
  const api = useAPI();
  return useCallback(
    (userId: string, message: string) =>
      api
        .post(`/users/${userId}/notifications`, { message })
        .then((response) => response.data),
    [api]
  );
}

export function useIdentification(userId: string) {
  const api = useAPI();
  return useQuery(["identification", userId], () =>
    api
      .get<{ identification: IdentificationWithUser[] }>(
        `/users/${userId}/identification`
      )
      .then((response) => response.data)
  );
}

export function useCreateIdentification(userId: string) {
  const api = useAPI();
  return useMutation<Identification, unknown, { description: string }>(
    ({ description }) =>
      api
        .post(`/users/${userId}/identification`, { description })
        .then((response) => response.data)
  );
}

export function useReturnIdentification(id: number, userId: string) {
  const api = useAPI();
  return useMutation<Identification>(() =>
    api
      .post(`/users/${userId}/identification/${id}/return`)
      .then((response) => response.data)
  );
}
