import { Button, Spacer, Text } from "@hackthenorth/north";
import React, { useState } from "react";
import styled from "styled-components";

import Footer from "../../static/img/footer.png";

import Categories from "./Categories/Categories";
import CsvModal from "./CsvModal";
import Skus from "./Skus/Skus";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default function Inventory() {
  const [page, setPage] = useState("skus");
  const [csvModalOpen, setCsvModalOpen] = useState(false);

  return (
    <>
      <Text as="title" mods="heading h1">
        Inventory Management
      </Text>
      <ButtonContainer>
        <div>
          <Button
            mods={{
              rgButton: true,
              rgSpaceRight: true,
              primary: page === "skus",
            }}
            onClick={() => setPage("skus")}
          >
            Skus
          </Button>
          <Button
            mods={{
              rgButton: true,
              rgSpaceRight: true,
              primary: page === "categories",
            }}
            onClick={() => setPage("categories")}
          >
            Categories
          </Button>
        </div>
        <Button
          mods={{
            rgButton: true,
            rgSpaceRight: true,
            primary: page === "csv",
          }}
          onClick={() => {
            setPage("csv");
            setCsvModalOpen(true);
          }}
        >
          Upload CSV
        </Button>
      </ButtonContainer>
      <Spacer height={20} />
      {csvModalOpen && (
        <CsvModal
          onClose={() => {
            setCsvModalOpen(false);
            setPage("categories");
          }}
        />
      )}
      {page === "skus" && <Skus />}
      {page === "categories" && <Categories />}
      <img src={Footer} alt="" style={{ width: "100%" }} />
    </>
  );
}
