import { Button, Flex, Spacer, Text } from "@hackthenorth/north";
import React, { useCallback, useMemo, useState } from "react";

import Modal from "../../common/Modal";
import NumberPicker from "../../common/NumberPicker";
import { Sku, useInventory, useRequestSku } from "../../util/api/api";

export default function ItemRequestModal(props: {
  onClose: () => void;
  sku: Sku;
}) {
  const requestSku = useRequestSku();

  const [count, setCount] = useState(1);
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [available, total] = useInventory(props.sku?.code ?? null);

  const { onClose, sku } = props;

  const submit = useCallback(async () => {
    const errors = [];
    setLoading(true);

    const realCount = Number(count);
    for (let i = 0; i < realCount; i++) {
      try {
        await requestSku(sku.code);
      } catch (e) {
        if (typeof e === "string") {
          errors.push(e.toLowerCase());
        }
      }
    }
    setLoading(false);

    if (errors.length) {
      setError(
        `Requested ${
          count - errors.length
        } item(s) successfully. Failed to request ${
          errors.length
        } item(s) due to ${Array.from(new Set(errors)).join(",")}.`
      );
    } else {
      onClose();
    }
  }, [requestSku, count, onClose, sku.code]);

  const actions = useMemo(
    () => (
      <>
        <Button
          onClick={onClose}
          disabled={isLoading}
          mods="rgButton rgSpaceRight rgJustifyStart"
        >
          Cancel
        </Button>
        <Flex mods="spacer" />
        <NumberPicker
          prepend="Request"
          min={0}
          max={sku?.type === "LOTTERY" ? 1 : available ?? undefined}
          value={count}
          setValue={setCount}
        />
        <Button
          onClick={submit}
          disabled={isLoading || count === 0}
          mods="rgButton rgSpaceLeft"
        >
          Submit
        </Button>
      </>
    ),
    [count, isLoading, onClose, submit, available, sku]
  );

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title={`Request ${sku.name}`}
      actions={actions}
    >
      <Text as="p" mods="block">
        An organizer will review your request once you request once you submit.
        If you are approved, we will notify you when it is ready to be picked
        up!
      </Text>
      <Spacer height={20} />
      <Text>
        {available}/{total} unit{total === 1 ? "" : "s"} available
      </Text>
      <Text mods="error">{error}</Text>
    </Modal>
  );
}
