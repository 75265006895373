import { createGlobalStyle } from "styled-components";

import {
  FontCastledownEOT,
  FontCastledownTTF,
  FontCastledownWOFF,
  FontCastledownWOFF2,
  FontInterBoldWOFF,
  FontInterBoldWOFF2,
  FontInterMediumWOFF,
  FontInterMediumWOFF2,
  FontInterRegularWOFF,
  FontInterRegularWOFF2,
} from "../static/fonts";

const globalStyles = createGlobalStyle`
  @font-face {
      font-family: "Castledown";
      src: url(${FontCastledownEOT});
      src: url(${FontCastledownEOT}#iefix) format("embedded-opentype"),
           url(${FontCastledownWOFF2}) format("woff2"),
           url(${FontCastledownWOFF}) format("woff"),
           url(${FontCastledownTTF}) format("truetype");
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
    font-family: "Inter";
    font-style:  normal;
    font-weight: 400;
    src: url(${FontInterRegularWOFF2}) format("woff2"),
        url(${FontInterRegularWOFF}) format("woff");
  }
  @font-face {
    font-family: "Inter";
    font-style:  normal;
    font-weight: 500;
    src: url(${FontInterMediumWOFF2}) format("woff2"),
        url(${FontInterMediumWOFF}) format("woff");
  }
  @font-face {
    font-family: "Inter";
    font-style:  normal;
    font-weight: 700;
    src: url(${FontInterBoldWOFF2}) format("woff2"),
        url(${FontInterBoldWOFF}) format("woff");
  }

  html {
    height: 100%;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background: ${({ theme }) => theme.globalConstants.color.tanSecondary};
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
`;

export default globalStyles;
