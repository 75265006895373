import { Text } from "@hackthenorth/north";
import React, { useState, useCallback } from "react";
import styled from "styled-components";

import Modal from "../../common/Modal";
import User from "../../common/User";
import { useUsers, User as TUser } from "../../util/api/api";

const Wrapper = styled.button`
  padding: 1em 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  display: block;
  cursor: pointer;
  text-align: left;
  background: transparent;
  border-style: none;
  border-bottom: 1px solid #aaa;
  > div:hover {
    opacity: 0.5;
  }
`;

const UsersText = styled(Text)`
  display: block !important;
  line-height: 1.5em !important;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Registered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(10, 68, 109, 0.1);
  border: 1px solid #0a446d;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: 100;
  text-align: center;
  color: #0a446d;
  padding: 8px 32px;
`;

function UserWrapper({ user }: { user: TUser }) {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);
  return (
    <>
      <Wrapper onClick={toggleOpen}>
        <UserContainer>
          <InfoContainer>
            <UsersText mods="bold">{user.name}</UsersText>
            <UsersText>{user.email}</UsersText>
          </InfoContainer>
          {user.registered && <Registered>Registered</Registered>}
        </UserContainer>
      </Wrapper>
      {open ? (
        <Modal isOpen={true} onClose={toggleOpen}>
          <Text as="h2" mods="heading h2">
            {user.name}
          </Text>
          <Text mods="subtle">{user.email}</Text>
          <User userId={user.uid} />
        </Modal>
      ) : null}
    </>
  );
}

export default function Users() {
  const { data } = useUsers();
  return (
    <>
      <Text as="h1" mods="heading h1">
        Users
      </Text>
      {data?.users.map((user) => (
        <UserWrapper user={user} key={user.uid} />
      ))}
    </>
  );
}
