import { North } from "@hackthenorth/north";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import themeDef from "./theme/theme";

const appElement = document.getElementById("root");

function getAuthToken(): string {
  const location = window.location;
  const jwt = new URLSearchParams(location.search).get("accessToken");

  if (jwt) {
    localStorage.setItem("token", jwt);
  }

  const token = localStorage.getItem("token");
  if (!token) {
    location.href = `https://auth.hackthenorth.com/?method=param&redirect=${encodeURIComponent(
      location.href.replace(/^https?:\/\//, "")
    )}`;
    return "";
  }

  return token;
}

function reAuthenticate() {
  localStorage.removeItem("token");
  getAuthToken();
}

ReactDOM.render(
  <North themeDefinition={themeDef}>
    <App
      baseURL={process.env.REACT_APP_BASE_URL as string}
      authToken={getAuthToken()}
      reAuthenticate={reAuthenticate}
    />
  </North>,
  appElement
);
