import { Text } from "@hackthenorth/north";
import React from "react";

import User from "../../common/User";
import { User as TUser, useUsersWithRequests } from "../../util/api/api";

export default function Wrapper() {
  const { data } = useUsersWithRequests();
  return (
    <>
      <Text as="h1" mods="heading h1">
        Requests
      </Text>
      {data?.users?.map((user: TUser) => (
        <>
          <Text as="h2" mods="heading h2">
            {user.name}
          </Text>
          <User userId={user.uid} />
        </>
      ))}
    </>
  );
}
