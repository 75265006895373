import { ThemeDefinition } from "@hackthenorth/north";
import { css } from "styled-components";

import { textSharedStyles, textModifiers } from "./text";

export const linkSharedStyles = css`
  ${textSharedStyles}
  color: ${({ theme }) => theme.globalConstants.color.bluePrimary2};
  display: inline-block;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const linkStyles: ThemeDefinition["link"] = {
  sharedStyles: linkSharedStyles,
  modifiers: {
    ...textModifiers,
    "no-decoration": css`
      text-decoration: none;
    `,
    rgTabItem: css`
      padding: ${({ theme }) => theme.globalConstants.padding.medium};
      border-bottom: 1px solid
        ${({ theme }) => theme.globalConstants.color.textDark};
    `,
  },
};
