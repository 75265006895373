import { Button, Text, Card, Spacer } from "@hackthenorth/north";
import Markdown from "markdown-to-jsx";
import React, { useCallback } from "react";
import styled from "styled-components";

import { PlaceLoader } from "../../../common/common";
import Image from "../../../common/Image";
import {
  useSku,
  useSkuImage,
  useDeleteSku,
  useCreateItem,
  Sku as TSku,
} from "../../../util/api/api";

import { SKUTYPE_OPTIONS } from "./Edit";
import SkuItem from "./Item/Item";

const Row = styled.div``;

const ColSmall = styled.div`
  display: inline-block;
  margin-right: 20px;
  min-width: 150px;
`;

const ColWide = styled.div``;

const Controls = styled.span`
  float: right;
`;

export default function Sku(props: {
  skuCode: string;
  setEditSku: (sku: TSku | undefined) => void;
  onClose: (skuCode?: null | string) => void;
}) {
  const { data: sku, refetch: refetchSku, isLoading } = useSku(props.skuCode);
  const { data: image, refetch: refetchImage } = useSkuImage(
    props.skuCode.toString()
  );

  const refetch = useCallback(async () => {
    await refetchSku();
    await refetchImage();
  }, [refetchImage, refetchSku]);

  const [deleteSku, { isLoading: deletingSku }] = useDeleteSku(props.skuCode);

  const handleDeleteSku = useCallback(async () => {
    if (
      window.confirm(
        `Delete ${sku?.name}? Associated requests will also be deleted.`
      )
    ) {
      await deleteSku();
      props.onClose(null);
    }
  }, [deleteSku, props, sku]);

  const [createItem, { isLoading: creatingItem }] = useCreateItem(
    props.skuCode
  );

  const addItem = useCallback(async () => {
    await createItem();
    refetch();
  }, [createItem, refetch]);

  return (
    <Card
      style={{
        backgroundColor: "#FFFFFF",
        border: "1px solid #008AC4",
        boxSizing: "border-box",
        borderRadius: "5px",
        padding: "20px",
      }}
    >
      <Row>
        <ColWide>
          <Text as="h3" mods="heading h3 block rgNoMarginTop">
            Photo
            <Controls>
              <Button
                mods="rgButton rgSpaceRight"
                onClick={() => props.setEditSku(sku)}
              >
                Edit
              </Button>
              <Button
                disabled={deletingSku}
                mods="rgButton rg"
                onClick={() => handleDeleteSku()}
              >
                Delete
              </Button>
            </Controls>
          </Text>
          <Image
            src={image ?? undefined}
            style={{
              opacity: Number(typeof image === "string"),
            }}
          />
        </ColWide>
      </Row>
      <Row>
        <ColSmall>
          <Text as="h3" mods="heading h3 block">
            Name
          </Text>
          <Text>{sku?.name ?? <PlaceLoader width={100} />}</Text>
        </ColSmall>
        <ColSmall>
          <Text as="h3" mods="heading h3 block">
            Category
          </Text>
          <Text>{sku?.category?.name ?? <PlaceLoader width={100} />}</Text>
        </ColSmall>
      </Row>
      <Row>
        <ColWide>
          <Text as="h3" mods="heading h3 block">
            Short Description
          </Text>
          <Text>{sku?.shortDescription ?? <PlaceLoader width={200} />}</Text>
        </ColWide>
      </Row>
      <Row>
        <ColWide>
          <Text as="h3" mods="heading h3 block">
            Long Description
          </Text>
          <Text>
            {sku?.longDescription ? (
              <Markdown options={{ disableParsingRawHTML: true }}>
                {sku.longDescription}
              </Markdown>
            ) : (
              <>
                <PlaceLoader width={300} />
                <br />
                <PlaceLoader width={300} />
              </>
            )}
          </Text>
        </ColWide>
      </Row>
      <Row>
        <ColSmall>
          <Text as="h3" mods="heading h3 block">
            Storage Location
          </Text>
          <Text>{sku?.location ?? <PlaceLoader width={100} />}</Text>
        </ColSmall>
        <ColSmall>
          <Text as="h3" mods="heading h3 block">
            Type
          </Text>
          <Text>
            {SKUTYPE_OPTIONS.find((o) => o.value === sku?.type)?.label ?? (
              <PlaceLoader width={120} />
            )}
          </Text>
        </ColSmall>
      </Row>
      <Text as="h2" mods="heading h2 block">
        Items
        <Controls>
          <Button
            mods="rgButton rgSpaceLeft"
            onClick={addItem}
            disabled={creatingItem || isLoading}
          >
            Add Item
          </Button>
        </Controls>
      </Text>
      <Spacer height={10} />
      {sku?.items?.map((item) => (
        <SkuItem key={item.id} sku={sku} item={item} refetch={refetch} />
      )) ?? <PlaceLoader width={150} />}
    </Card>
  );
}
