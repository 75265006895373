import { Spacer } from "@hackthenorth/north";
import React from "react";

import { ItemRequestWithMetadata } from "../../util/api/api";

import Requests from "./Requests/Requests";

export default function User({
  userId,
  onLoad,
}: {
  userId: string;
  onLoad?: (request: ItemRequestWithMetadata[]) => void;
}) {
  return (
    <>
      <Spacer height="11px" />
      <Requests userId={userId} onLoad={onLoad} />
    </>
  );
}
