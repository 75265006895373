import { useEffect, useState, useRef } from "react";

export default function useVisible() {
  const ref = useRef<HTMLElement | null>();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      // Update our state when observer callback fires
      setVisible(entry.isIntersecting);
    });

    const { current } = ref;

    if (current) {
      observer.observe(current);
    }
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, []);

  return [visible, ref] as [boolean, typeof ref];
}
