import React from "react";
import styled from "styled-components";

import { useNav } from "../common/Navbar";

import Home from "./Home/Home";
import Inventory from "./Inventory/Inventory";
import Items from "./Items/Items";
import Requests from "./Requests/Requests";
import Users from "./Users/Users";

const pages = {
  items: Items,
  home: Home,
  requests: Requests,
  users: Users,
  inventory: Inventory,
};

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.globalConstants.padding.medium};
  padding-top: 0;
`;

export default function Page() {
  const [page] = useNav();
  const PageComponent = pages[page];
  return <Wrapper>{PageComponent ? <PageComponent /> : null}</Wrapper>;
}
