import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { removeEmptyProperties } from "../removeEmptyProperties";

import { Category } from "./Category";
import useAPI, { useImage, useTopic } from "./core";
import { Item } from "./Item";

export type Skutype = "CHECKOUT" | "LOTTERY" | "FREE";
export interface Sku {
  name: string;
  code: string;
  shortDescription: string;
  longDescription: string;
  image: string;
  location: string;
  type: Skutype;
  categoryId: number;
}

export interface SkuUpload {
  name: string;
  code: string;
  shortDescription: string;
  longDescription: string;
  location: string;
  type: Skutype;
  category: string;
  quantity: number;
}

export function useSkus() {
  const api = useAPI();
  return useQuery("skus", () =>
    api.get<Sku[]>("/skus").then((response) => response.data)
  );
}

export function useSkuImage(code: string | null) {
  return useImage(`/skus/${code}/image`, code === null);
}

export function useCategoryImage(id: string | null) {
  return useImage(`/categories/${id}/image`, id === null);
}

export function useSku(code: string | null) {
  const api = useAPI();
  return useQuery(["sku", code], () =>
    api
      .get<Sku & { category: Category; items: Item[] }>(`/skus/${code}`)
      .then((response) => response.data)
  );
}

export function useCreateSku() {
  const api = useAPI();
  return useCallback(
    (sku: Sku) =>
      api
        .post(`/skus/`, removeEmptyProperties(sku))
        .then((response) => response.data),
    [api]
  );
}

export function useUploadSkus() {
  const api = useAPI();
  return useCallback(
    (skus: SkuUpload[]) =>
      api.post(`/skus/upload`, skus).then((response) => response.data),
    [api]
  );
}

export function useUpdateSku() {
  const api = useAPI();
  return useCallback(
    (sku: Sku) =>
      api
        .put(`/skus/${sku.code}`, removeEmptyProperties(sku))
        .then((response) => response.data),
    [api]
  );
}

export function useDeleteSku(skuCode: string) {
  const api = useAPI();
  return useMutation<Sku>(() =>
    api.delete(`/skus/${skuCode}`).then((response) => response.data)
  );
}

const inventoryCache: Record<
  string,
  { subscribers: number; available: number; total: number }
> = {};

export function useInventory(
  _skuCode: string | null
): [number | null, number | null] {
  const [available, setAvailableInternal] = useState(
    inventoryCache[_skuCode ?? ""]?.available ?? null
  );

  const [total, setTotal] = useState<number | null>(
    inventoryCache[_skuCode ?? ""]?.total ?? null
  );

  const [skuCode, setSkuCode] = useState("");
  useEffect(() => {
    if (typeof _skuCode === "string") {
      const timeout = setTimeout(() => setSkuCode(_skuCode), 250);
      return () => clearTimeout(timeout);
    }
    return;
  }, [_skuCode]);

  const setAvailable = useCallback(
    (count) => {
      if (inventoryCache && skuCode in inventoryCache) {
        inventoryCache[skuCode].available = count;
      }
      setAvailableInternal(count);
    },
    [skuCode]
  );

  const api = useAPI();

  useEffect(() => {
    if (skuCode === "") return;
    if (!(skuCode in inventoryCache)) {
      inventoryCache[skuCode] = { subscribers: 0, available: 0, total: 0 };
    }
    inventoryCache[skuCode].subscribers++;
    api.get(`/skus/${skuCode}/inventory`).then((response) => {
      setTotal(response.data.total as number);
      if (inventoryCache && skuCode in inventoryCache) {
        inventoryCache[skuCode].total = response.data.total as number;
      }

      setAvailable(response.data.available as number);
    });
    return () => {
      if (--inventoryCache[skuCode].subscribers === 0) {
        delete inventoryCache[skuCode];
      }
    };
  }, [setAvailable, skuCode, api]);

  useTopic(skuCode !== "" ? `inventory-${skuCode}` : null, setAvailable);

  return [available, total];
}
