import { ThemeDefinition } from "@hackthenorth/north";
import { css } from "styled-components";

export const dropdownInputStyles: ThemeDefinition["dropdownInput"] = {
  sharedStyles: css`
    font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
    font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
    color: ${({ theme }) => theme.globalConstants.color.textDark};
    width: 100%;
    outline: none;
    .select__menu {
      box-shadow: none;
      border: 1px solid ${({ theme }) => theme.globalConstants.color.rgPrimary};
    }
    .select__control {
      border: 1px solid ${({ theme }) => theme.globalConstants.color.rgPrimary};
      &:hover {
        border-color: ${({ theme }) =>
          theme.globalConstants.color.bluePrimary1};
      }
      outline: none;
      box-shadow: none;
    }
    .select__option {
      &:hover {
        cursor: pointer;
        border-radius: 5px;
        background-color: ${({ theme }) =>
          theme.globalConstants.color.bluePrimary3};
        color: ${({ theme }) => theme.globalConstants.color.textDark};
      }
    }
    .select__value-container {
      outline: none;
    }
    .select__single-value {
      outline: none;
      color: ${({ theme }) => theme.globalConstants.color.textDark};
    }
  `,
};
