import { ThemeDefinition } from "@hackthenorth/north";
import { css } from "styled-components";

import { hoverStyles, focusStyles, disabledStyles } from "../sharedStyles";

export const buttonStyles: ThemeDefinition["button"] = {
  sharedStyles: css`
    color: ${({ theme }) => theme.globalConstants.color.bluePrimary2};
    font-family: ${(props) => props.theme.globalConstants.fontFamily.body};
    font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
    line-height: 29px;

    border: 1px solid ${({ theme }) =>
      theme.globalConstants.color.bluePrimary2};
    border-radius: 50px;
    padding: 7px 32px;

    align-self: flex-start;

    ${hoverStyles}
    ${disabledStyles}
    ${focusStyles}
  `,
  modifiers: {
    primary: css`
      color: ${({ theme }) => theme.globalConstants.color.tanSecondary};
      background-color: ${({ theme }) =>
        theme.globalConstants.color.bluePrimary2};
    `,
    square: css`
      border-radius: 5px;
    `,
    link: css`
      padding: 0;
      margin: 0;
      line-height: 1em;
      display: inline;
      color: ${({ theme }) => theme.globalConstants.color.bluePrimary2};
      border-style: none;
      font-size: inherit;
      &:hover {
        text-decoration: underline;
      }
    `,
    rgSpaceRight: css`
      margin-right: 0.5em;
    `,
    rgSpaceLeft: css`
      margin-left: 0.5em;
    `,
    rgSpaceTop: css`
      margin-top: 0.5em;
    `,
    rgButton: css`
      line-height: 1em !important;
      font-size: 12px !important;
      padding: 0.8em 1em !important;
      margin: 0 0;
      transition: all 250ms !important;
      align-self: center;
      vertical-align: top;
    `,
    rgSmall: css`
      padding: 0.3em 0.5em !important;
    `,
    rgNumberPickerButton: css`
      border-radius: 100%;
      padding: 0px;
      font-size: 11px;
      text-align: center;
      box-sizing: border-box;
      border-style: none;
      color: ${({ theme }) => theme.globalConstants.color.textDark};
      &:hover {
        opacity: 1;
        background: ${({ theme }) => theme.globalConstants.color.bluePrimary3};
      }
    `,
    rgJustifyStart: css`
      flex: 0;
      justify-self: flex-start;
    `,
  },
};
