import { ThemeDefinition } from "@hackthenorth/north";
import { css } from "styled-components";

export const flexStyles: ThemeDefinition["flex"] = {
  sharedStyles: css``,
  modifiers: {
    spacer: css`
      flex: 1;
    `,
    wrap: css`
      flex-wrap: wrap;
    `,
  },
};
