import { Button, Text } from "@hackthenorth/north";
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.globalConstants.color.textDark};
  border-radius: 50px;
  display: flex;
  align-items: center;
  align-self: flex-end;
`;

interface Props {
  max?: number;
  min?: number;
  prepend?: string;
  value: number;
  setValue: (value: number) => void;
}

export default function NumberPicker({
  prepend,
  value,
  setValue,
  min,
  max,
}: Props) {
  // Ensure value stays within range
  useEffect(() => {
    if (typeof min !== "undefined" && value < min) setValue(min);
    if (typeof max !== "undefined" && value > max) setValue(max);
  }, [value, setValue, min, max]);

  const add = useCallback(() => {
    setValue(value + 1);
  }, [value, setValue]);

  const subtract = useCallback(() => {
    setValue(value - 1);
  }, [value, setValue]);

  return (
    <Wrapper>
      <Button
        mods="rgButton rgNumberPickerButton"
        onClick={subtract}
        disabled={value === min}
      >
        -
      </Button>
      <Text mods="rgNumberPickerCount">
        {prepend} {value}
      </Text>
      <Button
        mods="rgButton rgNumberPickerButton"
        onClick={add}
        disabled={value === max}
      >
        +
      </Button>
    </Wrapper>
  );
}
