import React from "react";
import styled from "styled-components";

const Wrapper = styled.span`
  display: inline-block;
  background: rgba(0, 0, 0, 0.05);
  color: transparent;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn ease 500ms;
`;
export default function PlaceLoader(props: { width: number }) {
  return <Wrapper style={{ width: `${props.width}px` }}>.</Wrapper>;
}
