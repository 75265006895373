import { GlobalConstants } from "@hackthenorth/north";

const WHITE = "#FFFFFF";

export const globalConstants: GlobalConstants = {
  color: {
    redPrimary1: "#F4AC9E",
    redPrimary2: "#F3675A",
    redPrimary3: "#F8D7D0",

    bluePrimary1: "#43AFDE",
    bluePrimary2: "#008AC4",
    bluePrimary3: "#CAEEFD",

    redSecondary: "#F2445B",
    greenSecondary: "#5ABCAA",
    navySecondary: "#004D85",
    mustardSecondary: "#FFB45C",
    tealSecondary: "#1DC1CC",
    tanSecondary: "#FFF6EE",
    greySecondary: "#EEF2F2",
    creamSecondary: "#FCEEE1",

    gradientRedBlue135: "linear-gradient(135deg, #F3625B 0%, #024B85 100%)",
    gradientRedBlue172: "linear-gradient(172.17deg, #F3625B 0%, #024B85 100%)",

    white: WHITE,
    textLight: WHITE,
    textDark: "#0E3460",
    textGrey: "#1E324A",
    textLightGrey: "#888888",

    rgPrimary: "#008AC4",
    rgPrimaryLight: "#CAEEFD",
  },
  fontFamily: {
    heading:
      "Castledown, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
    body:
      "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
    code: "Courier, monospace",
  },
  fontSize: {
    title: 52,
    h1: 40,
    hmobile: 32,
    h2: 24,
    h3: 20,
    bodyBig: 16,
    body: 14,
    bodySubtle: 12,
  },
  borderRadius: {
    small: 5,
    medium: 10,
    large: 50,
  },
  padding: {
    medium: "10px 16px",
    link: "18px",
  },
  boxShadow: {
    regular: "3px 4px 10px rgba(0, 0, 0, 0.05)",
  },
};

export default globalConstants;
