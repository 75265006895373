import { Spacer, Text } from "@hackthenorth/north";
import Markdown from "markdown-to-jsx";
import React from "react";

import Modal from "../../common/Modal";
import { Sku } from "../../util/api/api";

export default function ItemModal(props: { onClose: () => void; sku: Sku }) {
  const { onClose, sku } = props;
  return (
    <Modal isOpen={true} onClose={onClose} title={sku.name}>
      <Text as="p" mods="subtle block">
        {sku.shortDescription}
      </Text>
      <Spacer height={10} />
      <Text as="p" mods="block">
        <Markdown options={{ disableParsingRawHTML: true }}>
          {sku.longDescription}
        </Markdown>
      </Text>
    </Modal>
  );
}
