import { Text, TextInput } from "@hackthenorth/north";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { useNav } from "../../common/Navbar";
import PlaceLoader from "../../common/PlaceLoader";
import { useCategories, useSkus, Sku } from "../../util/api/api";
import { useSearch } from "../../util/search";

import ItemRow from "./ItemRow";

interface ItemGroup {
  id: number;
  name: string | null;
  skus: ((Sku & { groupName: string }) | null)[];
}

function ItemGroup(props: { query: string; group: ItemGroup }) {
  const { group, query } = props;
  const skus = useSearch(query, group.skus, [
    "name",
    "shortDescription",
    "groupName",
  ]);
  return skus.length > 0 ? (
    <>
      <Text as="h2" mods="heading h2">
        {group.name ?? <PlaceLoader width={150} />}
      </Text>
      {skus.map((sku, index) => (
        <ItemRow sku={sku} key={sku?.code ?? index} />
      ))}
    </>
  ) : null;
}

const SearchBar = styled(TextInput)`
  margin-top: -10px;
  margin-bottom: 15px;
`;

export default function Items() {
  const { data: skus } = useSkus();
  const { data: categories } = useCategories();

  const groups = useMemo(() => {
    if (!skus || !categories) {
      return [
        {
          id: -1,
          name: null,
          skus: [null, null, null],
        },
      ];
    }
    const categoryMap = categories.reduce<Record<string, ItemGroup>>(
      (acc, category) => {
        acc[category.id] = {
          ...category,
          skus: [],
        };
        return acc;
      },
      {}
    );

    for (const sku of skus) {
      categoryMap[sku.categoryId].skus.push({
        groupName: categoryMap[sku.categoryId].name ?? "",
        ...sku,
      });
    }

    return Object.values(categoryMap).sort((a, b) => {
      const aName = a.name;
      const bName = b.name;
      if (aName === null || bName === null) return 0;
      else if (aName < bName) return -1;
      else if (aName > bName) return 1;
      else return 0;
    });
  }, [skus, categories]);

  const [, params, setNav] = useNav();
  const [query, setQuery] = useState(params.q ?? "");
  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
      setNav("items", { q: e.target.value });
    },
    [setNav]
  );

  const autoFocus = useCallback((e: HTMLInputElement | null) => {
    e?.focus();
  }, []);

  return (
    <>
      <Text as="h1" mods="heading h1">
        Hardware
      </Text>
      <SearchBar
        ref={autoFocus}
        placeholder="Search all items"
        value={query}
        onChange={onSearchChange}
      />
      {groups.map((group) => (
        <ItemGroup key={group.id} group={group} query={query} />
      ))}
    </>
  );
}
