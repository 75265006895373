import {
  Button,
  Text,
  TextInput,
  TextAreaInput,
  Spacer,
  DropdownInput,
} from "@hackthenorth/north";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import Modal from "../../../common/Modal";
import {
  Sku,
  useCreateSku,
  useUpdateSku,
  useCategories,
  useSku,
  Skutype,
} from "../../../util/api/api";
import { base64Convert } from "../../../util/fileConversion";
import { translateErrors } from "../../../util/translateErrors";
import { CategoryOption } from "../Categories/Categories";

import { Option } from "./Skus";

export const SKUTYPE_OPTIONS = [
  { value: "CHECKOUT", label: "Checkout" },
  { value: "LOTTERY", label: "Lottery" },
  { value: "FREE", label: "Free" },
];

const ErrorMessage = styled.p`
  color: red;
`;

export default function Edit({
  onClose,
  seed,
}: {
  seed?: Sku;
  onClose: (skuCode?: string | null) => void;
}) {
  const { refetch } = useSku(seed?.code ?? null);
  const [errors, setErrors] = useState<string[]>([]);

  // Sku
  const [name, setName] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [shortDescription, setShortDescription] = useState<string>(""); // Short Description
  const [longDescription, setLongDescription] = useState<string>(""); // Long Description
  const [location, setLocation] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [type, setType] = useState<Skutype>("CHECKOUT");

  // HOOK: Get Category Options for Category selection
  const { data: categoryData } = useCategories();
  const categories = useMemo(
    () => categoryData?.map((c) => ({ value: c.id, label: c.name })) ?? [],
    [categoryData]
  );
  const [categoryId, setCategoryId] = useState<number>(-1);

  const onCategorySelect = useCallback((v: CategoryOption) => {
    setCategoryId(v.value);
  }, []);
  const selectedCategory = useMemo(
    () =>
      categories.find((c) => c.value === categoryId) ?? {
        value: -1,
        label: "Select a category",
      },
    [categories, categoryId]
  );

  const createSku = useCreateSku();
  const updateSku = useUpdateSku();

  useEffect(() => {
    if (typeof seed !== "undefined") {
      setName(seed.name);
      setCode(seed.code);
      setImage(seed.image);
      setShortDescription(seed.shortDescription);
      setLongDescription(seed.longDescription);
      setLocation(seed.location);
      setCategoryId(seed.categoryId);
      setType(seed.type);
    }
  }, [seed]);

  const handleSubmit = useCallback(() => {
    const sku: Sku = {
      image,
      name,
      code,
      shortDescription,
      longDescription,
      location,
      categoryId,
      type,
    };
    let skuRes = undefined;
    if (typeof seed === "undefined") {
      // POST
      skuRes = createSku(sku);
      // Handle creation of items next
    } else {
      // PUT
      skuRes = updateSku(sku);
      refetch();
      // Handle update of items next
    }
    skuRes.then((response) => {
      if (response.error) {
        setErrors(translateErrors(response.error));
      } else {
        onClose(code);
      }
    });
  }, [
    createSku,
    updateSku,
    image,
    name,
    code,
    shortDescription,
    longDescription,
    location,
    categoryId,
    seed,
    type,
    refetch,
    onClose,
  ]);

  const actions = (
    <>
      <Button mods="rgButton" onClick={() => onClose()}>
        Cancel
      </Button>
      <Button mods="rgButton rgSpaceLeft" onClick={handleSubmit}>
        Save
      </Button>
    </>
  );

  const fileUpload = async (e: React.FormEvent<HTMLInputElement>) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (typeof file !== "undefined") {
      const base64 = (await base64Convert(file)) as string;
      const url = base64.split(",")[1];
      setImage(url);
    }
  };

  return (
    <Modal isOpen={true} onClose={() => onClose()} actions={actions}>
      <Text as="h4" mods="heading h4 block">
        Photo
      </Text>
      <input type="file" onChange={(e) => fileUpload(e)} />
      <Spacer />
      <Text as="h4" mods="heading h4 block">
        Name
      </Text>
      <TextInput
        placeholder={"Enter a name"}
        value={name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setName(e.target.value);
        }}
      />
      <Text as="h4" mods="heading h4 block">
        Unique Sku Code
      </Text>
      <TextInput
        disabled={typeof seed !== "undefined"}
        placeholder={"Enter an unique sku code"}
        value={code}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setCode(e.target.value);
        }}
      />
      <Text as="h4" mods="heading h4 block">
        Category
      </Text>
      <DropdownInput
        placeholder="Select a category"
        value={selectedCategory}
        filterable={true}
        onChange={onCategorySelect}
        options={categories}
        disabled={!categories.length}
      />
      <Spacer />
      <Text as="h4" mods="heading h4 block">
        Short Description
      </Text>
      <TextInput
        placeholder={"Enter a short description"}
        value={shortDescription}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setShortDescription(e.target.value);
        }}
      />
      <Text as="h4" mods="heading h4 block">
        Long Description (markdown)
      </Text>
      <TextAreaInput
        placeholder={"Enter a description"}
        rows={4}
        value={longDescription}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setLongDescription(e.target.value);
        }}
      />
      <Text as="h4" mods="heading h4 block">
        Type
      </Text>
      <DropdownInput
        value={SKUTYPE_OPTIONS.find((x) => x.value === type)}
        onChange={(option: Option) => {
          setType(option.value as Skutype);
        }}
        options={SKUTYPE_OPTIONS}
      />
      <Text as="h4" mods="heading h4 block">
        Storage Location
      </Text>
      <TextInput
        placeholder={"Enter the internal storage location"}
        value={location}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setLocation(e.target.value);
        }}
      />
      {errors.map((error) => (
        <ErrorMessage key={error}>{error}</ErrorMessage>
      ))}
    </Modal>
  );
}
