import { Text } from "@hackthenorth/north";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";

import {
  ItemRequestWithMetadata,
  RequestStatus,
  useUserRequests,
} from "../../../util/api/api";
import NiceDate from "../../NiceDate";
import SkuRow from "../../SkuRow";

import getActions from "./Actions";
import RequestTimeline from "./RequestTimeline";

const groups: {
  status: RequestStatus;
  display: string;
  description?: string;
  style?: string;
}[] = [
  { status: RequestStatus.FULFILLED, display: "In use" },
  {
    status: RequestStatus.READY,
    display: "Ready",
    description: "These items are ready for you to pick up!",
  },
  { status: RequestStatus.PENDING, display: "Pending" },
  { status: RequestStatus.APPROVED, display: "Approved" },
  { status: RequestStatus.RETURNED, display: "Returned" },
  { status: RequestStatus.DENIED, display: "Denied" },
  { status: RequestStatus.CANCELED, display: "Canceled" },
];

const Group = styled.div`
  &.READY {
    padding: 10px;
    padding-top: 0px;
    margin: 0 -10px 20px -10px;
    border-radius: ${({ theme }) => theme.globalConstants.borderRadius.small}px;
  }
`;

const Description = styled(Text)`
  margin-bottom: 15px !important;
  margin-top: -2px !important;
  display: block !important;
`;

export default function Requests({
  userId,
  onLoad,
}: {
  userId: string;
  onLoad?: (request: ItemRequestWithMetadata[]) => void;
}) {
  const { data, refetch, error, isFetching } = useUserRequests(userId);
  useEffect(() => {
    if (data && onLoad) {
      onLoad(data.requests);
    }
  }, [onLoad, data]);

  const groupData = useMemo(() => {
    const groups: { [k: string]: ItemRequestWithMetadata[] } = {};
    for (const request of data?.requests ?? []) {
      if (!(request.status in groups)) {
        groups[request.status] = [];
      }
      groups[request.status].push(request);
    }

    Object.keys(groups).forEach((key: string) => {
      groups[key] = groups[key].sort((a, b) =>
        a.updatedAt <= b.updatedAt ? 1 : -1
      );
    });

    return groups;
  }, [data]);

  return (
    <>
      <Text mods="error">{error}</Text>
      {groups.map(
        (group) =>
          group.status in groupData && (
            <Group className={group.status} key={group.status}>
              <Text as="h4" mods="heading h4">
                {group.display}
              </Text>
              <Description>{group.description}</Description>
              {groupData[group.status].map((request) => {
                const actions = getActions(request, refetch);
                return (
                  <SkuRow
                    disabled={isFetching}
                    key={request.id}
                    sku={request.sku}
                    item={request.item}
                    right={
                      <Text mods="robogooseSkurowRight">
                        {group.display} <NiceDate date={request.updatedAt} />
                      </Text>
                    }
                    small={actions === null}
                  >
                    {actions}
                    <RequestTimeline request={request} />
                  </SkuRow>
                );
              })}
            </Group>
          )
      )}
    </>
  );
}
