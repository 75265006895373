import { ThemeDefinition } from "@hackthenorth/north";
import { css } from "styled-components";

import { hoverStyles, disabledStyles } from "../sharedStyles";

export const sharedInputStyles = css`
  appearance: none;
  padding: 4px 12px;
  width: 100%;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
  line-height: 29px;
  color: ${({ theme }) => theme.globalConstants.color.textDark};
  border: 1px solid ${({ theme }) => theme.globalConstants.color.rgPrimary};
  border-radius: 5px;
  &:focus {
    border-color: ${({ theme }) => theme.globalConstants.color.bluePrimary1};
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.globalConstants.color.textLightGrey};
  }
  ${hoverStyles}
  ${disabledStyles}
  transition: all 200ms;
`;

export const textInputStyles: ThemeDefinition["textInput"] = {
  sharedStyles: css`
    ${sharedInputStyles}
  `,
  modifiers: {
    error: css`
      border-color: ${({ theme }) => theme.globalConstants.color.redSecondary};
    `,
  },
};
